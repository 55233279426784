import axios from "axios";

// for developement
// const baseURL = "http://localhost:5000/api/";

// for teeparam test  build use
// const baseURL = "http://154.61.174.151/teeparam.tracking.tcms.api/api/";

// for teeparam live build use
const baseURL = "https://tracking.teeparam.com/TCMS_WEBTRACKER_API/api/";

const instance = axios.create({
  baseURL,
});

export default {
  instance,
  baseURL,
};
