import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ShipmentTracking from "./ShipmentTracking";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

export default function App() {
  const theme = useTheme();
  const belowMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Router>
        {/* <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
                 maxWidth:'100vw'
          }}
        >
          <Box> */}
            <Routes>
              <Route path="/" element={<ShipmentTracking />} />
            </Routes>
          {/* </Box>
        </Box> */}
      </Router>
    </>
  );
}
