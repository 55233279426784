import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
  IconButton,
  FormHelperText,
  Chip,
  Dialog,
  Grid,
  useTheme,
  Paper,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./App.css";
import { Chrono } from "react-chrono";
import Loader from "./Loader";
import axios from "./axiosConfig";
import AdjustIcon from "@mui/icons-material/Adjust";

import images from "./logo2.png";
import images3 from "./grocery-cart.png";
import images2 from "./ticket.png";
import images1 from "./transfer2.png";
import { TimelineOppositeContent } from "@mui/lab";

const ShipmentTrackingMobile = () => {
  const tokent =
    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJldmVudGxpc3QiOlt7IlVzZXJJRCI6IjEiLCJMb2dpbkNvZGUiOiIwMSIsIkxvZ2luTmFtZSI6IkFkbWluIiwiRW1haWxJZCI6ImFkbWluQGdtYWlsLmNvbSIsIlVzZXJUeXBlIjoiQURNSU4ifV0sImlhdCI6MTYzODM1NDczMX0.ZW6zEHIXTxfT-QWEzS6-GuY7bRupf2Jc_tp4fXIRabQ";
  const [country, setCountry] = useState("IN");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [headerData, setHeaderData] = useState(null);
  const [bookingData, setBookingData] = useState([]);
  const theme = useTheme();
  const belowMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    GetDBCountries();
  }, []);

  // get all Active Buyer data
  const GetDBCountries = async () => {
    try {
      const res = await axios.instance.get("/IN/GetDBCountries", {
        headers: { Authorization: tokent, "Content-Type": "application/json" },
      });
      setCountryData(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch tracking details based on selected country and tracking number
  const fetchTrackingDetails = async () => {
    setOpenLoader(true);
    try {
      const url = `/${country}/GetTrackingDetails`;
      const response = await axios.instance.post(
        url,
        { TrackNo: trackingNumber },
        {
          headers: {
            Authorization: tokent,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.resultSet1[0].valid === "Invalid Tracking Number") {
        setErrorMessage("Invalid Tracking Number");
        setOpenLoader(false);
      } else {
        const { resultSet1, resultSet2 } = response.data;
        setHeaderData(resultSet1[0]);
        setBookingData(resultSet2);
        setOpenLoader(false);
      }
    } catch (error) {
      console.error("Error fetching tracking details:", error);
      setOpenLoader(false);
    }
  };

  const handleTrack = () => {
    if (!trackingNumber) {
      setErrorMessage("Tracking number cannot be empty.");
    } else {
      setErrorMessage("");
      fetchTrackingDetails();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleTrack();
    }
  };

  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: "white",
        borderRadius: belowMediumScreen ? 0 : 10,
      }}
      my={belowMediumScreen ? 0 : 12}
    >
      {/* Header Section */}
      <Box sx={{ textAlign: "center" }} mb={4}>
        <a
          href="https://www.teeparam.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <div>
            <img src={images} alt="logo" />
          </div>
        </a>
        <Typography
          variant={belowMediumScreen ? "subtitle2" : "h5"}
          sx={{
            marginTop: 2,
            marginBottom: 2,
            fontWeight: "bold",
            color: "#536493",
          }}
        >
          Track Your Shipment Status
        </Typography>
        {belowMediumScreen ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 2,
              borderRadius: 30,
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter your tracking number"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              onKeyPress={handleKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TextField
                      select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#fff",
                        border: "none",
                        "& .MuiSelect-select": {
                          padding: "12px",
                          fontWeight: "bold",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      SelectProps={{
                        renderValue: (selected) => {
                          const selectedCountry = countryData.find(
                            (option) => option.CountryCode === selected
                          );
                          return selectedCountry
                            ? selectedCountry.CountryCode
                            : selected;
                        },
                      }}
                    >
                      {countryData.map((option) => (
                        <MenuItem
                          key={option.CountryCode}
                          value={option.CountryCode}
                        >
                          {option.CountryName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTrack} color="primary">
                      <ArrowRightIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                width: belowMediumScreen ? "80vw" : "50vw",
                backgroundColor: "#fff",
                border: "none",
              }}
              error={Boolean(errorMessage)}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 2,
              borderRadius: 30,
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter your tracking number"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              onKeyPress={handleKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TextField
                      select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#fff",
                        border: "none",
                        "& .MuiSelect-select": {
                          padding: "12px",
                          fontWeight: "bold",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      {countryData.map((option) => (
                        <MenuItem
                          key={option.CountryCode}
                          value={option.CountryCode}
                        >
                          {option.CountryName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTrack} color="primary">
                      <ArrowRightIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                width: belowMediumScreen ? "100vw" : "50vw",
                backgroundColor: "#fff",
                border: "none",
              }}
              error={Boolean(errorMessage)}
            />
          </Box>
        )}
        {errorMessage && (
          <FormHelperText error sx={{ textAlign: "center", marginTop: 1 }}>
            {errorMessage}
          </FormHelperText>
        )}
      </Box>
      {headerData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Card
            sx={{ boxShadow: 0, width: belowMediumScreen ? "100vw" : "70vw" }}
            elevation={0}
            py={2}
            fullWidth
          >
            <CardContent>
              <Divider fullWidth sx={{ mt: 1 }}>
                <Chip label="Tracking Status" size="small" color="primary" />
              </Divider>

              <Stack
                direction={belowMediumScreen ? "column" : "row"}
                divider={
                  <Divider
                    orientation={belowMediumScreen ? "horizontal" : "vertical"}
                    flexItem
                  />
                }
                mt={3}
                mb={0}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                component={Paper}
                elevation={2}
                p={2}
                borderRadius={7}
              >
                <Stack
                  direction="column"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography variant="h6" color="#50B498" align="center">
                    Origin
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    {headerData.SCountry || "N/A"}
                  </Typography>
                </Stack>

                <Stack
                  direction="column"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography variant="h6" color="#50B498" align="center">
                    Destination
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    {headerData.RCountry || "N/A"}
                  </Typography>
                </Stack>

                <Stack
                  direction="column"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography variant="h6" color="#50B498" align="center">
                    Weight
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    {headerData.TotalWeight || "N/A"} kg
                  </Typography>
                </Stack>
              </Stack>

              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0, // Remove opposite content space
                    padding: 0,
                  },
                  marginLeft: 0, // Align the entire timeline to the left
                }}
              >
                {bookingData
                  .reduce((acc, curr) => {
                    const existingTrack = acc.find(
                      (item) => item.TrackName === curr.TrackName
                    );
                    if (!existingTrack) {
                      acc.push({
                        TrackName: curr.TrackName,
                        Remarks: curr.Remarks,
                        packages: [],
                      });
                    }

                    const currentIndex = acc.findIndex(
                      (item) => item.TrackName === curr.TrackName
                    );
                    acc[currentIndex].packages.push({
                      BoxNo: curr.BoxNo,
                      BookTrackDate: curr.BookTrackDate,
                      BookTrackTime: curr.BookTrackTime,
                      Remarks: curr.Remarks,
                    });

                    return acc;
                  }, [])
                  .reverse() // Ensure reverse order for latest updates first
                  .map((event, index) => (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineDot color="primary">
                          <AdjustIcon />
                        </TimelineDot>
                        {index < bookingData.length - 1 && (
                          <TimelineConnector />
                        )}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Card
                          sx={{
                            boxShadow: 0,
                            padding: 1,
                            elevation: 0,
                            mb: 4,
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            color="primary"
                            textTransform={"capitalize"}
                            fontWeight="bold"
                          >
                            {event.TrackName}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="#16325B"
                            fontWeight={"bold"}
                          >
                            No. of Packages: {event.packages.length}
                          </Typography>
                          {/* Directly map over available packages */}
                          {event.packages.reverse().map((packageData, pkgIdx) => (
                            <div key={pkgIdx} style={{ marginBottom: 5 }}>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                <span
                                  style={{
                                    color: "#508D4E",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Pkg {packageData.BoxNo}
                                </span>
                                : {packageData.BookTrackDate}{" "}
                                {packageData.BookTrackTime}
                                <br />
                              </Typography>
                              <Typography
                                variant="body2"
                                textTransform={"lowercase"}
                              >
                                {packageData.Remarks || "No remarks"}
                              </Typography>
                            </div>
                          ))}
                        </Card>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
              </Timeline>
            </CardContent>
          </Card>
        </Box>
      )}
      {/* Service Cards Section */}
      <Divider fullWidth sx={{ mt: 1, mx: 10, mb: 3 }}>
        <Chip
          label="Our Services"
          size="small"
          color="info"
          variant="outlined"
        />
      </Divider>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        {[
          {
            name: "Money Transfer",
            imgSrc: images1,
            link: "https://teeparamexchange.co.uk/",
          },
          {
            name: "Air Ticketing",
            imgSrc: images2,
            link: "https://www.whitelabelhosting.co.uk/",
          },
          {
            name: "FMCG Wholesaler",
            imgSrc: images3,
            link: "https://www.teeparamwholesale.co.uk/",
          },
        ].map((service) => (
          <Grid
            item
            xs={12}
            key={service.name}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <a
              href={service.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Card
                sx={{
                  boxShadow: 2,
                  textAlign: "center",
                  borderRadius: 7,
                  padding: 1,
                  mx: 2,
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow: 4,
                  },
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      width: belowMediumScreen ? 20 : 50,
                      height: belowMediumScreen ? 20 : 50,
                      backgroundColor: "#2F3645",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 auto",
                      marginBottom: 1,
                      padding: 2,
                    }}
                  >
                    <img
                      src={service.imgSrc}
                      alt={service.name}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Box>
                  <Typography
                    variant={belowMediumScreen ? "body2" : "subtitle2"}
                    color="#2F3645"
                    sx={{ fontWeight: "bold" }}
                  >
                    {service.name}
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
        ))}
      </Grid>

      {/* loader popup dialog box */}
      <Dialog
        open={openLoader}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            backgroundColor: "#ffffff00",
          }}
        >
          <Loader />
        </Stack>
      </Dialog>
    </Box>
  );
};

export default ShipmentTrackingMobile;
