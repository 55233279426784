import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
  IconButton,
  FormHelperText,
  Chip,
  Dialog,
  Grid,
  useTheme,
  Paper,
  Button,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./App.css";
import { Chrono } from "react-chrono";
import Loader from "./Loader";
import axios from "./axiosConfig";
import AdjustIcon from "@mui/icons-material/Adjust";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

import images from "./logo2.png";
import images3 from "./grocery-cart.png";
import images2 from "./ticket.png";
import images1 from "./transfer2.png";
import ShipmentTrackingMobile from "./ShipmentTrackingMobile";
import { TimelineOppositeContent } from "@mui/lab";

const ShipmentTracking = () => {
  const tokent =
    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJldmVudGxpc3QiOlt7IlVzZXJJRCI6IjEiLCJMb2dpbkNvZGUiOiIwMSIsIkxvZ2luTmFtZSI6IkFkbWluIiwiRW1haWxJZCI6ImFkbWluQGdtYWlsLmNvbSIsIlVzZXJUeXBlIjoiQURNSU4ifV0sImlhdCI6MTYzODM1NDczMX0.ZW6zEHIXTxfT-QWEzS6-GuY7bRupf2Jc_tp4fXIRabQ";
  const [country, setCountry] = useState("IN");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [headerData, setHeaderData] = useState(null);
  const [bookingData, setBookingData] = useState([]);
  const theme = useTheme();
  const belowMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    GetDBCountries();
  }, []);

  // get all Active Buyer data
  const GetDBCountries = async () => {
    try {
      const res = await axios.instance.get("/IN/GetDBCountries", {
        headers: { Authorization: tokent, "Content-Type": "application/json" },
      });
      setCountryData(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch tracking details based on selected country and tracking number
  const fetchTrackingDetails = async () => {
    setOpenLoader(true);
    try {
      const url = `/${country}/GetTrackingDetails`;
      const response = await axios.instance.post(
        url,
        { TrackNo: trackingNumber },
        {
          headers: {
            Authorization: tokent,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.resultSet1[0].valid === "Invalid Tracking Number") {
        setErrorMessage("Invalid Tracking Number");
        setOpenLoader(false);
      } else {
        const { resultSet1, resultSet2 } = response.data;
        setHeaderData(resultSet1[0]);
        setBookingData(resultSet2);
        setOpenLoader(false);
      }
    } catch (error) {
      console.error("Error fetching tracking details:", error);
      setOpenLoader(false);
    }
  };

  const handleTrack = () => {
    if (!trackingNumber) {
      setErrorMessage("Tracking number cannot be empty.");
    } else {
      setErrorMessage("");
      fetchTrackingDetails();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleTrack();
    }
  };

  // Calculate the fixed number of packages based on the "PARCEL BOOKED" status
  const fixedPackageCount = bookingData
    .filter((item) => item.TrackName === "PARCEL BOOKED")
    .reduce((uniquePackages, item) => {
      if (!uniquePackages.includes(item.BoxNo)) {
        uniquePackages.push(item.BoxNo);
      }
      return uniquePackages;
    }, []).length;

  return (
    <div
      style={{
        minWidth: "100vw",
      }}
    >
      {belowMediumScreen ? (
        <ShipmentTrackingMobile />
      ) : (
        <>
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1100,
              backgroundColor: "#0072E5",
              padding: "6px 16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#fff",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Contact Information */}
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <PhoneIcon />
                <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                  <a
                    href="tel:+442080049855"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    +44 20 8004 9855
                  </a>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <EmailIcon />
                <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                  <a
                    href="mailto:info@teeparam.com"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    info@teeparam.com
                  </a>
                </Typography>
              </Box>
            </Box>

            <Button
              sx={{
                backgroundColor: "#00A44B",
                color: "#fff",
                padding: "4px 16px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#007e3a",
                },
              }}
              size="small"
              onClick={() =>
                window.open("https://teeparamexchange.co.uk/", "_blank")
              }
            >
              MONEY TRANSFER
            </Button>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={headerData ? 6 : 12}>
              <Box
                sx={{
                  padding: 4,
                  backgroundColor: "white",
                  maxHeight: "85vh",
                  minHeight: headerData ? "75vh" : "70vh",
                  borderRadius: 10,
                }}
                m={4}
              >
                {/* Header Section */}
                <Box
                  sx={{
                    backgroundColor: "#fff",

                    textAlign: "center",
                    padding: "16px",
                  }}
                >
                  <a
                    href="https://www.teeparam.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <div>
                      <img src={images} alt="logo" />
                    </div>
                  </a>
                  <Typography
                    variant={belowMediumScreen ? "subtitle2" : "h5"}
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                      fontWeight: "bold",
                      color: "#536493",
                    }}
                  >
                    Track Your Shipment Status
                  </Typography>
                  {belowMediumScreen ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 2,
                        borderRadius: 30,
                      }}
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter your tracking number"
                        value={trackingNumber}
                        onChange={(e) => setTrackingNumber(e.target.value)}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <TextField
                                select
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                variant="outlined"
                                sx={{
                                  backgroundColor: "#fff",
                                  border: "none",
                                  "& .MuiSelect-select": {
                                    padding: "12px",
                                    fontWeight: "bold",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                }}
                                SelectProps={{
                                  renderValue: (selected) => {
                                    const selectedCountry = countryData.find(
                                      (option) =>
                                        option.CountryCode === selected
                                    );
                                    return selectedCountry
                                      ? selectedCountry.CountryCode
                                      : selected;
                                  },
                                }}
                              >
                                {countryData.map((option) => (
                                  <MenuItem
                                    key={option.CountryCode}
                                    value={option.CountryCode}
                                  >
                                    {option.CountryName}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleTrack} color="primary">
                                <ArrowRightIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: belowMediumScreen ? "100vw" : "50vw",
                          backgroundColor: "#fff",
                          border: "none",
                        }}
                        error={Boolean(errorMessage)}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 2,
                        borderRadius: 30,
                      }}
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter your tracking number"
                        value={trackingNumber}
                        onChange={(e) => setTrackingNumber(e.target.value)}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <TextField
                                select
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                variant="outlined"
                                sx={{
                                  backgroundColor: "#fff",
                                  border: "none",
                                  "& .MuiSelect-select": {
                                    padding: "12px",
                                    fontWeight: "bold",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                }}
                              >
                                {countryData.map((option) => (
                                  <MenuItem
                                    key={option.CountryCode}
                                    value={option.CountryCode}
                                  >
                                    {option.CountryName}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleTrack} color="primary">
                                <ArrowRightIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: belowMediumScreen ? "100vw" : "50vw",
                          backgroundColor: "#fff",
                          border: "none",
                        }}
                        error={Boolean(errorMessage)}
                      />
                    </Box>
                  )}
                  {errorMessage && (
                    <FormHelperText
                      error
                      sx={{ textAlign: "center", marginTop: 1 }}
                    >
                      {errorMessage}
                    </FormHelperText>
                  )}
                </Box>

                {/* Service Cards Section */}
                <Divider fullWidth sx={{ mt: 1, mx: 10, mb: 3 }}>
                  <Chip
                    label="Our Services"
                    size="small"
                    color="info"
                    variant="outlined"
                  />
                </Divider>
                <Grid container spacing={2}>
                  {[
                    {
                      name: headerData
                        ? "Money <br /> Transfer"
                        : "Money Transfer",
                      imgSrc: images1,
                      link: "https://teeparamexchange.co.uk/",
                    },
                    {
                      name: headerData
                        ? "Air <br /> Ticketing"
                        : "Air Ticketing",
                      imgSrc: images2,
                      link: "https://www.whitelabelhosting.co.uk/",
                    },
                    {
                      name: headerData
                        ? "FMCG <br /> Wholesaler"
                        : "FMCG Wholesaler",
                      imgSrc: images3,
                      link: "https://www.teeparamwholesale.co.uk/",
                    },
                  ].map((service) => (
                    <Grid item xs={12} sm={12} md={4} key={service.name}>
                      <a
                        href={service.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <Card
                          sx={{
                            boxShadow: 2,
                            textAlign: "center",
                            borderRadius: 7,
                            padding: 1,
                            mx: 2,
                            boxShadow:
                              "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                            "&:hover": {
                              transform: "translateY(-10px)",
                              boxShadow: 4,
                            },
                          }}
                        >
                          <CardContent>
                            <Box
                              sx={{
                                width: belowMediumScreen ? 20 : 20,
                                height: belowMediumScreen ? 20 : 20,
                                backgroundColor: "#2F3645",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "0 auto",
                                marginBottom: 1,
                                padding: 2,
                              }}
                            >
                              <img
                                src={service.imgSrc}
                                alt={service.name}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            </Box>
                            <Typography
                              variant={
                                belowMediumScreen ? "subtitle2" : "subtitle2"
                              }
                              color="#2F3645"
                              sx={{ fontWeight: "bold" }}
                              dangerouslySetInnerHTML={{ __html: service.name }}
                            />
                          </CardContent>
                        </Card>
                      </a>
                    </Grid>
                  ))}
                </Grid>

                {/* loader popup dialog box */}
                <Dialog
                  open={openLoader}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  PaperProps={{
                    sx: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <Stack
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: "#ffffff00",
                    }}
                  >
                    <Loader />
                  </Stack>
                </Dialog>
              </Box>
            </Grid>
            {headerData && (
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                  m={4}
                >
                  <Card
                    sx={{
                      boxShadow: 0,
                      borderRadius: 10,
                      maxHeight: "85vh",
                      overflowY: "scroll",
                    }}
                    elevation={0}
                    pb={2}
                    fullWidth
                  >
                    <CardContent>
                      {headerData && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            position: "sticky",
                            top: 0,
                            backgroundColor: "#fff",
                            zIndex: 1000,
                          }}
                        >
                          <Card
                            sx={{
                              boxShadow: 0,
                              width: belowMediumScreen ? "100vw" : "70vw",
                            }}
                            elevation={0}
                            pt={2}
                            fullWidth
                          >
                            <CardContent>
                              <Divider fullWidth sx={{ mt: 1 }}>
                                <Chip
                                  label="Tracking Status"
                                  size="small"
                                  color="primary"
                                />
                              </Divider>

                              <Stack
                                direction={belowMediumScreen ? "column" : "row"}
                                divider={
                                  <Divider
                                    orientation={
                                      belowMediumScreen
                                        ? "horizontal"
                                        : "vertical"
                                    }
                                    flexItem
                                  />
                                }
                                mt={3}
                                mb={0}
                                justifyContent={"space-evenly"}
                                alignItems={"center"}
                                component={Paper}
                                elevation={2}
                                p={2}
                                borderRadius={7}
                              >
                                <Stack
                                  direction="column"
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                >
                                  <Typography
                                    variant="h6"
                                    color="#50B498"
                                    align="center"
                                  >
                                    Origin
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    align="center"
                                    textTransform={"capitalize"}
                                  >
                                    {`${headerData.SCity}, ${headerData.SCountry}` ||
                                      "N/A"}
                                  </Typography>
                                </Stack>

                                <Stack
                                  direction="column"
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                >
                                  <Typography
                                    variant="h6"
                                    color="#50B498"
                                    align="center"
                                  >
                                    Destination
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    align="center"
                                    textTransform={"capitalize"}
                                  >
                                    {`${headerData.RCity}, ${headerData.RCountry}` ||
                                      "N/A"}
                                  </Typography>
                                </Stack>

                                <Stack
                                  direction="column"
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                >
                                  <Typography
                                    variant="h6"
                                    color="#50B498"
                                    align="center"
                                  >
                                    Weight
                                  </Typography>
                                  <Typography variant="body2" align="center">
                                    {headerData.TotalWeight || "N/A"} kg
                                  </Typography>
                                </Stack>
                              </Stack>
                            </CardContent>
                          </Card>
                        </Box>
                      )}
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: "100%" }}
                      >
                        <Timeline
                          position="alternate"
                          sx={{
                            maxWidth: belowMediumScreen ? "100vw" : "800px",
                            width: "100%",
                          }}
                        >
                          {bookingData
                            .reduce((acc, curr) => {
                              const existingTrack = acc.find(
                                (item) => item.TrackName === curr.TrackName
                              );
                              if (!existingTrack) {
                                acc.push({
                                  TrackName: curr.TrackName,
                                  Remarks: curr.Remarks,
                                  packages: [],
                                });
                              }

                              const currentIndex = acc.findIndex(
                                (item) => item.TrackName === curr.TrackName
                              );

                              acc[currentIndex].packages.push({
                                BoxNo: curr.BoxNo,
                                BookTrackDate: curr.BookTrackDate,
                                BookTrackTime: curr.BookTrackTime,
                                Remarks: curr.Remarks,
                              });
                              return acc;
                            }, [])
                            .reverse()
                            .map((event, index) => (
                              <TimelineItem key={index}>
                                <TimelineOppositeContent color="text.secondary">
                                  <Typography
                                    variant="subtitle2"
                                    color="primary"
                                    textTransform={"capitalize"}
                                    fontWeight="bold"
                                  >
                                    {event.TrackName}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    color="#16325B"
                                    fontWeight={"bold"}
                                  >
                                    No. of Packages: {fixedPackageCount}
                                  </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                  <TimelineDot color="primary">
                                    <AdjustIcon />
                                  </TimelineDot>
                                  {index < bookingData.length - 2 && (
                                    <TimelineConnector />
                                  )}
                                </TimelineSeparator>
                                <TimelineContent>
                                  <Card
                                    sx={{
                                      boxShadow: 0,
                                      padding: 1,
                                      elevation: 0,
                                      mb: 4,
                                    }}
                                  >
                                    {/* Directly map over available packages */}
                                    {event.packages
                                      .reverse()
                                      .map((packageData, pkgIdx) => (
                                        <div
                                          key={pkgIdx}
                                          style={{ marginBottom: 5 }}
                                        >
                                          <Typography
                                            variant="caption"
                                            color="textSecondary"
                                          >
                                            <span
                                              style={{
                                                color: "#508D4E",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Pkg {packageData.BoxNo}
                                            </span>
                                            : {packageData.BookTrackDate}{" "}
                                            {packageData.BookTrackTime}
                                            <br />
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            textTransform={"lowercase"}
                                          >
                                            {packageData.Remarks ||
                                              "No remarks"}
                                          </Typography>
                                        </div>
                                      ))}
                                  </Card>
                                </TimelineContent>
                              </TimelineItem>
                            ))}
                        </Timeline>
                      </Stack>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

export default ShipmentTracking;
